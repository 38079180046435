<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-lg-12 text-center mt-6 mb-6 mr-5 ml-5">
      <h4 class="mb-0"  style="line-height:1.55rem;">{{ datos.titulo }}</h4>
      <!-- <small class="text-muted mb-3 fs-14" v-if="datos.fechaRegistro != null && datos.fechaRegistro != ''">{{ datos.fechaRegistro | date }}</small> -->
    </div>
    <div class="col-md-12 col-lg-12 text-description">
      <div v-html="datos.contenido" class="p-6"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-detalle-nocontenido",
  props: ["datos"],
  data: () => ({}),
  methods: {},
  mounted: function() {},
};
</script>
