<template>
  <div class="pb-6">
    <!-- <div class="row justify-content-center">
      <div class="col-md-12 text-center mt-6">
        <h4 class="mb-0" style="line-height:1.55rem;">{{ datos.titulo }}</h4>
        <small class="text-muted mb-3 fs-14" v-if="datos.fechaRegistro != null && datos.fechaRegistro != ''">{{ datos.fechaRegistro | date }}</small> 
      </div>
    </div> -->
    <div class="row justify-content-center p-5">
      <div class="col-lg-6 mt-2 mb-4 text-center">
        <div id="slider-image">
          <template v-for="imagen in datos.imagenes">
            <div class="p-1 text-center">
              <img :src="`${filesUrl + imagen.ruta}`" data-provide="lightbox" class="cr-zoom img-fluid" style="height:450px; width:100%; object-fit: contain;" />
            </div>
          </template>
        </div>
        <small class="text-info ml-1" style="font-size:8pt;"><i class="fa fa-search-plus"></i> Hacer clic en la imagen para agrandar</small>
        <!-- <div id="slider-thumb">
          <template v-for="imagen in datos.imagenes">
            <div class="p-2 text-center">
              <img :src="`${filesUrl + imagen.ruta}`" class="cursor-pointer" style="height:90px;  width:100%; object-fit: cover;" />
            </div>
          </template>
        </div> -->
      </div>
      <div class="col-lg-6 mt-2 text-description">
            <h4 class="mb-3">{{ datos.titulo }}</h4>
            <!-- <small class="text-muted mb-3 fs-14" v-if="datos.fechaRegistro != null && datos.fechaRegistro != ''">{{ datos.fechaRegistro | date }}</small> -->
        <div v-html="datos.contenido"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-detalle-imagen",
  props: ["datos"],
  data: () => ({}),
  methods: {},
  mounted: function() {
    startSlider("slider-image", [1, 1, 1, 1, 1, 1]);
  },
};
</script>
