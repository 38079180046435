<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 text-center mt-6 mb-6 ml-5 mr-5">
      <h4 class="mb-0" style="line-height:1.55rem;">{{ datos.titulo }}</h4>
      <!-- <small class="text-muted mb-3 fs-14" v-if="datos.fechaRegistro != null && datos.fechaRegistro != ''">{{ datos.fechaRegistro | date }}</small> -->
    </div>
    <div class="col-lg-12 text-center" v-if="datos.rutaImagen != null && datos.rutaImagen != ''">
      <img style="max-height:420px;width:auto;object-fit: contain;" class="img-fluid rounded shadow-1 cr-zoom" :src="`${filesUrl + datos.rutaImagen}`" :alt="datos.titulo" data-provide="lightbox" />
    </div>
    <div class="col-lg-12 col-xs-12 m-0 p-0 text-center ">
      <small class="text-info ml-1" style="font-size:8pt;"><i class="fa fa-search-plus"></i> Hacer clic en la imagen para agrandar</small>
    </div>
     <div class="col-lg-12 col-xs-12 m-0 p-0 text-center mb-2 " v-if="datos.creditosFoto != null && datos.creditosFoto != ''">
      <small class="text-dark ml-1 fs-13 fw-600">{{ datos.creditosFoto }}</small>
    </div>
    <div class="col-xs-12 col-md-12 col-lg-12 text-description">
      <div v-html="datos.contenido" class="pb-5 pr-5 pl-5 pt-4"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-detalle-simple",
  props: ["datos"],
  data: () => ({}),
  methods: {},
  mounted: function() {},
};
</script>
