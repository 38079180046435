<template>
  <div class="pb-6">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-8 text-center mt-6 mb-6 mr-5 ml-5">
        <h4 class="mb-0"  style="line-height:1.55rem;">{{ datos.titulo }}</h4>
        <!-- <small class="text-muted mb-3 fs-14" v-if="datos.fechaRegistro != null && datos.fechaRegistro != ''">{{ datos.fechaRegistro | date }}</small> -->
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 text-description">
        <div v-html="datos.contenido" class="pb-5 pr-5 pl-5"></div>
      </div>
    </div>
    <div class="row pb-6">
      <template v-for="video in datos.videos">
        <div class="col-md-6 text-center">
          <div class="card mt-2 bg-transparent" style="border:0px !important;">
            <div class="card-header bg-transparent" style="border:0px !important;">
              <h6 class="text-dark fw-600" v-if="video.nombre != null && video.nombre != ''">{{ video.nombre }}</h6>
            </div>
            <div class="card-body" style="padding-top:0px;">
              <iframe class="iframe-video" :src="video.ruta" width="560" height="315" style="border:none;" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
            </div>
            <!-- <iframe width="560" height="315" class="iframe-video"  style="border:none;" v-if="typeVideo(video.ruta) == 'youtube'" :src="video.ruta" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen" frameborder="0"></iframe> -->
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-detalle-video",
  props: ["datos"],
  data: () => ({}),
  methods: {},
  mounted: function() {},
};
</script>
