<template>
  <div class="pb-6">
    <div class="row justify-content-center">
      <!-- <div class="col-md-12 text-center mt-6 mr-4 ml-4 mb-3">
        <h4 class="mb-0" style="line-height:1.55rem;">{{ datos.titulo }}</h4>
       <small class="text-muted mb-3 fs-14" v-if="datos.fechaRegistro != null && datos.fechaRegistro != ''">{{ datos.fechaRegistro | date }}</small> 
      </div> -->
    </div>
    <div class="row justify-content-center pt-5 pb-5 pl-5 pr-5 ">
      <div class="col-lg-6 text-center mb-3  d-none d-sm-block ">
        <div id="slider-image">
          <template v-for="imagen in datos.imagenes">
            <div class="p-1 text-center">
              <img :src="`${filesUrl + imagen.ruta}`" data-provide="lightbox" class="cr-zoom" style="height:450px; width:100%; object-fit: contain;" />
            </div>
          </template>
        </div>
        <small class="text-info" style="font-size:8pt;"><i class="fa fa-search-plus"></i> Hacer clic en la imagen para agrandar</small>
      </div>
      <div class="col-lg-6 text-center mb-3  d-block d-sm-none">
        <div id="slider-image-xs">
          <template v-for="imagen in datos.imagenes">
            <div class="p-1 text-center">
              <img :src="`${filesUrl + imagen.ruta}`" data-provide="lightbox" class="cr-zoom" style="height:350px; width:100%; object-fit: contain;" />
            </div>
          </template>
        </div>
        <small class="text-info" style="font-size:8pt;"><i class="fa fa-search-plus"></i> Hacer clic en la imagen para agrandar</small>
      </div>
      <div class="col-lg-6 text-description">
        <h4 class="mb-3">{{ datos.titulo }}</h4>
        <div v-html="datos.contenido"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-0 ml-6">
        <h5>Videos informativos</h5>
      </div>
      <template v-for="video in datos.videos">
        <div class="col-md-6 text-center">
          <div class="card mt-2 bg-transparent" style="border:0px !important;">
            <div class="card-header bg-transparent" style="border:0px !important;">
              <h6 class="text-dark fw-600" v-if="video.nombre != null && video.nombre != ''">{{ video.nombre }}</h6>
            </div>
            <div class="card-body" style="padding-top:0px;">
              <iframe class="iframe-video" :src="video.ruta" width="560" height="315" style="border:none;" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
            </div>
            <!-- <iframe width="560" height="315" class="iframe-video"  style="border:none;" v-if="typeVideo(video.ruta) == 'youtube'" :src="video.ruta" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen" frameborder="0"></iframe> -->
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-detalle-imagen-video",
  props: ["datos"],
  data: () => ({}),
  methods: {},
  mounted: function() {
    //startSliderNav("slider-image", "slider-thumb", [3, 3, 2, 2, 1, 1]);
    startSlider("slider-image", [1, 1, 1, 1, 1, 1]);
    startSlider("slider-image-xs", [1, 1, 1, 1, 1, 1]);
  },
};
</script>
